var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"login-form-content"},[_c('h1',[_vm._v("礼品代发")]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"账号登陆"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [{ required: true, message: '请输入手机号' }],
                    validateTrigger: 'change',
                  } ]),expression:"[\n                  'username',\n                  {\n                    rules: [{ required: true, message: '请输入手机号' }],\n                    validateTrigger: 'change',\n                  },\n                ]"}],staticStyle:{"width":"267px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"手机号码/用户名"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                    validateTrigger: 'blur',
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '请输入密码' }],\n                    validateTrigger: 'blur',\n                  },\n                ]"}],staticStyle:{"width":"267px","height":"40px"},attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"}})],1)],1),_c('a-tab-pane',{key:"tab2",attrs:{"tab":"短信登录"}},[_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{staticStyle:{"width":"64px","height":"40px"},attrs:{"default-value":"+86"}}),_c('a-input',{staticStyle:{"width":"267px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"手机号码"}})],1)],1),_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'code',
                    {
                      rules: [{ required: true, message: '请输入验证码' }],
                      validateTrigger: 'blur',
                    } ]),expression:"[\n                    'code',\n                    {\n                      rules: [{ required: true, message: '请输入验证码' }],\n                      validateTrigger: 'blur',\n                    },\n                  ]"}],staticStyle:{"width":"221px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"请输入验证码"}}),_c('a-button',{staticStyle:{"width":"110px","height":"40px","color":"rgba(251,52,52,1)","textAlign":"center","fontWeight":"bold"},attrs:{"tabindex":"-1","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(
                    (!_vm.state.smsSendBtn && '获取验证码') || _vm.state.time + ' s'
                  )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCode.apply(null, arguments)}}})],1)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }