<template>
  <div class="content">
    <div class="login-form">
      <div class="login-form-content">
        <h1>礼品代发</h1>
        <a-form
          id="formLogin"
          class="user-layout-login"
          ref="formLogin"
          :form="form"
          @submit="handleSubmit"
        >
          <a-tabs
            :activeKey="customActiveKey"
            :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
            @change="handleTabClick"
          >
            <a-tab-pane key="tab1" tab="账号登陆">
              <a-form-item>
                <a-input
                  size="large"
                  type="text"
                  placeholder="手机号码/用户名"
                  style="width:267px;height:40px"
                  v-decorator="[
                    'username',
                    {
                      rules: [{ required: true, message: '请输入手机号' }],
                      validateTrigger: 'change',
                    },
                  ]"
                ></a-input>
              </a-form-item>

              <a-form-item>
                <a-input
                  size="large"
                  type="password"
                  autocomplete="false"
                  style="width:267px;height:40px"
                  placeholder="密码"
                  v-decorator="[
                    'password',
                    {
                      rules: [{ required: true, message: '请输入密码' }],
                      validateTrigger: 'blur',
                    },
                  ]"
                ></a-input>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="tab2" tab="短信登录">
              <a-form-item>
                <a-input-group compact>
                  <a-input style="width:64px;height:40px" default-value="+86" />
                  <a-input
                    style="width:267px;height:40px"
                    size="large"
                    type="text"
                    placeholder="手机号码"
                  />
                </a-input-group>
              </a-form-item>
              <a-form-item>
                <a-input-group compact>
                  <a-input
                    size="large"
                    type="text"
                    placeholder="请输入验证码"
                    style="width:221px;height:40px"
                    v-decorator="[
                      'code',
                      {
                        rules: [{ required: true, message: '请输入验证码' }],
                        validateTrigger: 'blur',
                      },
                    ]"
                  />

                  <a-button
                    style="width:110px;height:40px;color:rgba(251,52,52,1);textAlign:center;fontWeight:bold"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getCode"
                    v-text="
                      (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                    "
                  ></a-button>
                </a-input-group>
              </a-form-item>
            </a-tab-pane>
          </a-tabs>
          <a-form-item>
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
            >登录</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { sipSms, sipLogin, accountGet } from '@api'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'

export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      userList: [],
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      query: '',
      inviterCode: Vue.ls.get('DEFAULT_INVITE_KEY')
    }
  },
  created () {
    // 检查本地的token有没有过期
    const token = Vue.ls.get(ACCESS_TOKEN)
    console.log('token', token)
    if (token) {
      accountGet().then(res => {
        console.log('res', res)
        if (res && res.data && res.data.oemUid) {
          location.href = `${location.origin}/api/oem/client?oemUid=${res.data.oemUid}&token=${token}`
        }
      })
    }
  },
  mounted () {
    this.query = Vue.ls.get('DEFAULT_INVITE_KEY')
  },
  methods: {
    handleTabClick (key) {
      this.customActiveKey = key
    },
    goRegister () {
      if (this.inviterCode) {
        this.$router.push({ name: 'register', query: { c: this.inviterCode } })
      } else {
        this.$router.push({ name: 'register' })
      }
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey
      } = this
      state.loginBtn = true
      const validateFieldsKey =
        customActiveKey === 'tab1'
          ? ['username', 'password']
          : ['username', 'code']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          if (customActiveKey === 'tab1') {
            // 用户名密码
          } else if (customActiveKey === 'tab2') {
            // 手机号验证码
          }
          sipLogin(values)
            .then(res => {
              if (res.success) {
                const data = res.data
                Vue.ls.set(ACCESS_TOKEN, data.token, 7 * 24 * 60 * 60 * 1000)
                this.$notification.success({
                  message: '欢迎',
                  description: `${data.username}，欢迎回来`
                })
                location.href = `${location.origin}/api/oem/client?oemUid=${data.oemUid}&token=${data.token}`
              } else {
                this.requestFailed(res)
              }
            })
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCode (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        $message
      } = this
      validateFields(['username'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = $message.loading('验证码发送中..', 0)
          sipSms(values.username, 2)
            .then(res => {
              if (res.success) {
                setTimeout(hide, 2500)
                this.$notification['success']({
                  message: '提示',
                  description: '验证码获取成功',
                  duration: 8
                })
              } else {
                setTimeout(hide, 2500)
                this.$notification['error']({
                  message: '错误',
                  description: res.msg,
                  duration: 4
                })
              }
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.$notification['error']({
                message: '错误',
                description: err.message,
                duration: 4
              })
            })
        }
      })
    },
    requestFailed (err) {
      this.$notification['error']({
        message: '错误',
        description: err.msg,
        duration: 4
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/bg1.png");
}
.login-form {
  position: absolute;
  width: 360px;
  height: 480px;
  text-align: center;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: #e5e5e5 0px 0px 6px 1px;
  .login-form-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.login-button {
  width: 100%;
  height: 48px;
}
</style>
