var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register_wrapper"},[_c('header',[_c('div',{staticClass:"header_content"},[_c('div',{staticClass:"left",on:{"click":function () {
            _vm.$router.push({ path: '/index', query: { c: _vm.$route.query.c } });
          }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.baseData.logo),expression:"baseData.logo"}],staticClass:"logo"},[_c('img',{attrs:{"src":_vm.baseData.logo,"alt":"logo"}})]),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.baseData.systemTitle))])]),_c('div',{staticClass:"right",on:{"click":function () {
            _vm.$router.push({ path: '/index', query: { c: _vm.$route.query.c } });
          }}},[_c('div',{staticClass:"goindex"},[_vm._v(_vm._s(_vm.baseData.systemTitle)+"首页")])])])]),_c('div',{staticClass:"user_layout"},[_c('div',{staticClass:"register_body"},[_c('div',{staticClass:"title",on:{"click":function () {
            _vm.$router.push({ path: '/index', query: { c: _vm.$route.query.c } });
          }}},[_vm._v(" 欢迎注册"+_vm._s(_vm.baseData.systemTitle)+" ")]),_c('a-form',{ref:"formRegister",attrs:{"form":_vm.form,"id":"formRegister"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStep1 == true),expression:"showStep1 == true"}]},[_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{staticStyle:{"width":"64px","height":"40px"},attrs:{"default-value":"+86"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'mobile',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入正确的手机号',
                        pattern: /^1[3456789]\d{9}$/
                      },
                      { validator: this.handlePhoneCheck }
                    ],
                    validateTrigger: ['change', 'blur']
                  }
                ]),expression:"[\n                  'mobile',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '请输入正确的手机号',\n                        pattern: /^1[3456789]\\d{9}$/\n                      },\n                      { validator: this.handlePhoneCheck }\n                    ],\n                    validateTrigger: ['change', 'blur']\n                  }\n                ]"}],staticStyle:{"width":"267px","height":"40px"},attrs:{"size":"large","placeholder":"11 位手机号"}})],1)],1),_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code',
                  {
                    rules: [{ required: true, message: '请输入验证码' }],
                    validateTrigger: 'blur'
                  }
                ]),expression:"[\n                  'code',\n                  {\n                    rules: [{ required: true, message: '请输入验证码' }],\n                    validateTrigger: 'blur'\n                  }\n                ]"}],staticStyle:{"width":"221px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"请输入验证码"}}),_c('a-button',{staticStyle:{"width":"110px","height":"40px","color":"rgba(251,52,52,1)","textAlign":"center","fontWeight":"bold"},attrs:{"tabindex":"-1","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(
                  (!_vm.state.smsSendBtn && '获取验证码') || _vm.state.time + ' s'
                )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCode.apply(null, arguments)}}})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"next_step",attrs:{"size":"large","type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("立即注册")])],1),_c('div',{staticClass:"gologin_now"},[_vm._v(" 已有账号， "),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v("立即登录？")])])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStep2 == true),expression:"showStep2 == true"}]},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [
                    {
                      required: true,
                      message: '小写字母开头，加数字，6~16位',
                      pattern: /^[a-zA-Z]\w{5,16}$/
                    }
                  ]
                }
              ]),expression:"[\n                'username',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '小写字母开头，加数字，6~16位',\n                      pattern: /^[a-zA-Z]\\w{5,16}$/\n                    }\n                  ]\n                }\n              ]"}],attrs:{"size":"large","placeholder":"用户名"}})],1),_c('a-popover',{attrs:{"placement":"rightTop","trigger":"click","visible":_vm.state.passwordLevelChecked}},[_c('template',{slot:"content"},[_c('div',{style:({ width: '200px' })},[_c('div',{class:['user-register', _vm.passwordLevelClass]},[_vm._v(" 强度： "),_c('span',[_vm._v(_vm._s(_vm.passwordLevelName))])]),_c('a-progress',{attrs:{"percent":_vm.state.percent,"showInfo":false,"strokeColor":_vm.passwordLevelColor}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v("请至少输入 6 个字符。请不要使用容易被猜到的密码。")])])],1)]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      { required: true, message: '至少6位密码，区分大小写' },
                      { validator: this.handlePasswordLevel }
                    ],
                    validateTrigger: ['change', 'blur']
                  }
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      { required: true, message: '至少6位密码，区分大小写' },\n                      { validator: this.handlePasswordLevel }\n                    ],\n                    validateTrigger: ['change', 'blur']\n                  }\n                ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"},on:{"click":_vm.handlePasswordInputClick}})],1)],2),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'rePassword',
                {
                  rules: [
                    { required: true, message: '至少6位密码，区分大小写' },
                    { validator: this.handlePasswordCheck }
                  ],
                  validateTrigger: ['change', 'blur']
                }
              ]),expression:"[\n                'rePassword',\n                {\n                  rules: [\n                    { required: true, message: '至少6位密码，区分大小写' },\n                    { validator: this.handlePasswordCheck }\n                  ],\n                  validateTrigger: ['change', 'blur']\n                }\n              ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"再次确认密码"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"register-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.registerBtn,"disabled":_vm.registerBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("完成注册")])],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"size":"large"},on:{"click":_vm.goBackStep}},[_vm._v("返回")])],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }